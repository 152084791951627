import { default as contactsecK6rf8N8MMeta } from "/usr/src/app/pages/contacts.vue?macro=true";
import { default as cookie_45policy4sxEq9aoPYMeta } from "/usr/src/app/pages/cookie-policy.vue?macro=true";
import { default as incubatorE9kRDfqJkIMeta } from "/usr/src/app/pages/incubator.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as philosophy7LrYqewUNmMeta } from "/usr/src/app/pages/philosophy.vue?macro=true";
import { default as privacy_45policy1IN0o4tDn5Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
import { default as worksJTdzwkCw7CMeta } from "/usr/src/app/pages/works.vue?macro=true";
export default [
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/usr/src/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/usr/src/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "incubator",
    path: "/incubator",
    component: () => import("/usr/src/app/pages/incubator.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "philosophy",
    path: "/philosophy",
    component: () => import("/usr/src/app/pages/philosophy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "works",
    path: "/works",
    component: () => import("/usr/src/app/pages/works.vue").then(m => m.default || m)
  }
]