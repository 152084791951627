<script setup lang="ts">
const props = defineProps<{
  title: string,
  destination: string
}>()

const router = useRouter()
const click = () => {
  router.push({name: props.destination})
}
</script>

<template>
  <button class="bg-mk-darkgray text-mk-primary rounded-3xl px-8 py-2 font-bold flex items-center space-x-2"
          @click="click">
    <span>{{ title }}</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
         class="size-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"/>
    </svg>
  </button>
</template>
